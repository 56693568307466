<template>
  <div>
    <location />
    <div class="page wrap-1200">
      <h3 class="subtitle">{{ $t("text.tournament.main_title_1") }}</h3>
      <p>
        {{ $t("text.tournament.main_sub_title_1") }}
      </p>
      <h5>{{ $t("text.tournament.main_title_2") }}</h5>
      <p class="mb-4 mb-lg-5">
        {{ $t("text.tournament.main_sub_title_2") }}<br />
        <button 
          type="button"
          class="btn btn-primary btn-sm mt-2 px-3 fs-14"
          @click="$router.push('/SignUp')"          
        >
          {{ $t("text.login.sign_up") }}
        </button>
      </p>

      <div class="join_tourn_step row row-cols-1 row-cols-md-3 g-4 g-lg-5">
        <div class="col">
          <div class="card">
            <div class="row row-cols-md-1">
              <div class="col-sm-8">
                <div class="card-body p-0 pb-4 pb-sm-0 pb-md-4">
                  <span class="badge rounded-pill">STEP 1</span>
                  <!-- <h6>{{ $t("text.tournament.title_menu_step_1") }}</h6> -->
                  <p>
                    {{ $t("text.tournament.title_sub_step_1") }}
                  </p>
                </div>
              </div>
              <div class="col-sm-4 align-self-center align-self-md-end">
                <img src="/img/join_tourn_step1.jpg" class="img-fluid border" />
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <div class="row row-cols-md-1">
              <div class="col-sm-8">
                <div class="card-body p-0 pb-4 pb-sm-0 pb-md-4">
                  <span class="badge rounded-pill">STEP 2</span>
                  <!-- <h6>{{ $t("text.tournament.title_menu_step_2") }}</h6> -->
                  <p>
                    {{ $t("text.tournament.title_sub_step_2") }}
                  </p>
                </div>
              </div>
              <div class="col-sm-4 align-self-center align-self-md-end">
                <img src="/img/join_tourn_step2.jpg" class="img-fluid border" />
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <div class="row row-cols-md-1">
              <div class="col-sm-8">
                <div class="card-body p-0 pb-4 pb-sm-0 pb-md-4">
                  <span class="badge rounded-pill">STEP 3</span>
                  <!-- <h6>{{ $t("text.tournament.title_menu_step_3") }}</h6> -->
                  <p>
                    {{ $t("text.tournament.title_sub_step_3") }}
                  </p>
                </div>
              </div>
              <div class="col-sm-4 align-self-center align-self-md-end">
                <img src="/img/join_tourn_step3.jpg" class="img-fluid border" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";

export default {
  name: "JoinTournament",
  components: {
    location,
  },
};
</script>