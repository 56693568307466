<template>
  <div>
    <location />

    <div class="p31_view page wrap-1200">
      <h3 class="font-montserrat start-m">Member’s information</h3>
      <p class="info">Basic information</p>
      <div class="row g-0 border-top-dgray">
        <div class="col-lg-3 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold">Nickname</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerbasicinfo.nickname }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold">Gender</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell">
                {{ changeGender(playerbasicinfo.gender) }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Favorite Location</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerbasicinfo.shopname }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold">
                Sign-Up Date</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ getDate(playerbasicinfo.date) }}</p>
            </div>
          </div>
        </div>
      </div>
      <p class="info">Round summary</p>
      <div class="row g-0 border-top-dgray">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Avg. putting</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">
                {{ playerpalyinfo.avggreenhit | toFixed }}m
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Total rounds</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.playcnt | comma }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Avg. driving distance</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.avgdriver }}yd</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Fairway hit</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.farewayrate }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold">GIR</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.greenrate }}%</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold">Par save</label>
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.parsaverate }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Avg. no. of stroke</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.avgshot | comma }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 border-bottom">
          <div class="row g-0">
            <div class="col d-table bg-gray">
              <label class="d-table-cell align-center fw-bold"
                >Lowest stroke record</label
              >
            </div>
            <div class="col d-table">
              <p class="d-table-cell">{{ playerpalyinfo.minshot | comma }}</p>
            </div>
          </div>
        </div>
      </div>
      <span class="text-red"
        >* 18hole finish, No mulligan, above advanced level</span
      >
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/tournament";
import { myMixin } from "@/mixin/myMixin";
import "@/filter/common";

export default {
  name: "TournamentMember",
  components: {
    location,
  },
  data() {
    return {
      playerno: this.$route.params.playerno,
      playerbasicinfo: [],
      playerpalyinfo: [],
    };
  },
  methods: {
    get_playerinfo() {
      ax.get_playerinfo(this.playerno, (flag, data) => {
        if (flag) {
          this.playerbasicinfo = data.playerbasicinfo;
          this.playerpalyinfo = data.playerpalyinfo;
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_playerinfo();
  },
  mixins: [myMixin],
};
</script>
