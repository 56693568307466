<template>
  <div>
    <location />
    <div class="page">
      <swiper
        :options="{
          slidesPerView: 'auto',
          freeMode: true,
          //initialSlide: tab - 1,
        }"
        class="swiper-tab-main"
      >
        <swiper-slide :class="{ active: tab === 1 }"
          ><div @click="tab_change(1)">
            {{ $t("button.tournament.tournament_condition") }}
          </div></swiper-slide
        >
        <swiper-slide :class="{ active: tab === 2 }"
          ><div @click="tab_change(2)">
            {{ $t("button.tournament.tournament_rank") }}
          </div></swiper-slide
        >
      </swiper>
      <ul class="nav nav-pills wrap-1200" id="pills-tab" role="tablist">
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            id="pills-1-tab"
            type="button"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            :class="{ active: tab == 1 }"
            @click="tab_change(1)"
          >
            {{ $t("button.tournament.tournament_condition") }}
          </button>
        </li>
        <li class="nav-item col-6" role="presentation">
          <button
            class="w-100 nav-link"
            id="pills-2-tab"
            type="button"
            role="tab"
            aria-controls="pills-2"
            aria-selected="false"
            :class="{ active: tab === 2 }"
            @click="tab_change(2)"
          >
            {{ $t("button.tournament.tournament_rank") }}
          </button>
        </li>
      </ul>

      <div
        class="tab-content wrap-1200"
        id="pills-tabContent"
      >
        <Router-View />
        <div class="btn-bottom-wrapper">
          <b-button
            size="xl"
            variant="outline-primary"
            :aria-label="$t('button.common.list')"
            @click="
              $router
                .push({
                  name: 'TournamentSchedule',
                  query: {
                    searchtype: $route.query.searchtype,
                    searchname: $route.query.searchname,
                  },
                })
                .catch(() => {})
            "
          >
            {{ $t("button.common.list") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "TournamentScheduleView",
  components: {
    location,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      /*swiperOptions: {
        slidesPerView: 'auto',
        freeMode: true
      }*/
    };
  },
  computed: {
    tab: function () {
      return this.$route.name === "Ranking" ? 2 : 1;
    },
  },
  methods: {
    tab_change(tab_no) {
      //this.tab = tab_no
      if (tab_no === 1)
        this.$router
          .push({
            name: "Condition",
            query: {
              searchname: this.$route.query.searchname,
              searchtype: this.$route.query.searchtype,
            },
          })
          .catch(() => {});
      else if (tab_no === 2)
        this.$router
          .push({
            name: "Ranking",
            query: {
              searchname: this.$route.query.searchname,
              searchtype: this.$route.query.searchtype,
            },
          })
          .catch(() => {});
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
};
</script>